import React from 'react'
import {
  LiveData0Wrapper,
  LiveDataStyledImageInstaWeather,
} from './'
import SkeletonMonitoringData from './components/MonitoringData/skeleton'
import SkeletonSunMoon from './components/SunMoon/skeleton'
import SkeletonTemperature from './components/Temperature/skeleton'
import SkeletonRowLocation from './components/RowLocation/skeleton'
import SkeletonImageInstaWeather from './components/ImageInstaWeather/skeleton'
import styled from 'styled-components'
import { LiveData0DataWrapper, LiveData0FirstWrapper, LiveData0SubWrapper } from '../DataWeather'

const StyledSkeletonImageInstaWeather = styled(LiveDataStyledImageInstaWeather)``

const SkeletonLiveData = ({ ...props }) => (
  <LiveData0Wrapper {...props}>
    <LiveData0DataWrapper>
      <LiveData0FirstWrapper>
        <SkeletonRowLocation />
        <SkeletonTemperature />
        <SkeletonSunMoon />
      </LiveData0FirstWrapper>
      <LiveData0SubWrapper>
        <SkeletonMonitoringData />
      </LiveData0SubWrapper>
    </LiveData0DataWrapper>
    <StyledSkeletonImageInstaWeather as={SkeletonImageInstaWeather} />
  </LiveData0Wrapper>
)

export default SkeletonLiveData
