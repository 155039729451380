import isStolApp from '../is-stolapp'
import isServerSide from '../is-server-side'
import axios from 'axios'
import Config from '../../config'
import southTyrolCommunities from '../../config/data/southTyrolCommunities'
import { setStoredItem, getStoredItem } from '../stored-item'

function error(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`)
}

const options = {
  enableHighAccuracy: true,
}

/**
 * A Promise which gets you the client coordinates.
 * @returns {Promise<unknown>}
 */
const clientCoordinates = () =>
  new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      return reject()
    }
    if (isStolApp()) {
      if (!isServerSide()) {
        let coordinatesArray = window.navigator.userAgent.split('gps(')[1].split(')')[0].split(',')

        if (
          coordinatesArray &&
          coordinatesArray.length > 0 &&
          coordinatesArray[0] !== 'null' &&
          coordinatesArray[0] !== undefined &&
          coordinatesArray[0] !== null &&
          coordinatesArray[0] !== 'undefined'
        ) {
          let coordinates = {
            latitude: parseFloat(coordinatesArray[0]),
            longitude: parseFloat(coordinatesArray[1]),
            accuracy: parseFloat(coordinatesArray[2]),
          }
          return resolve(coordinates, error, options)
        } else {
          navigator.geolocation.getCurrentPosition(
            ({ coords }) => resolve(coords),
            () => reject(),
            options,
          )
        }
      } else {
        reject()
      }
    }
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => resolve(coords),
      () => reject(),
      options,
    )
  })

const southTyrolLat = [47.092, 46.22]
const southTyrolLong = [10.381, 12.477]

/**
 * checks if specific coordinates are in SouthTyrol
 * @param lat
 * @param long
 * @returns {boolean}
 * @constructor
 */
const isLocationInSouthTyrol = (lat, long) => {
  if (lat !== undefined && long !== undefined) {
    let latitude = parseFloat(lat)
    let longitude = parseFloat(long)
    if (!isNaN(latitude) && !isNaN(longitude)) {
      if (
        latitude > southTyrolLat[0] ||
        latitude < southTyrolLat[1] ||
        longitude < southTyrolLong[0] ||
        longitude > southTyrolLong[1]
      ) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

/**
 * gets you the commune of the current user if he is in southTyrol.
 * @returns {Promise<*>}
 */
const getRecommendedCommune = async () => {
  let currentCommune
  if (getStoredItem('location_community') === null) {
    let currentCoordinates = await clientCoordinates()
    let currentAdress = await axios.get(
      Config.geoUrl('&lat=' + currentCoordinates.latitude + '&lon=' + currentCoordinates.longitude + '&zoom=18'),
    )
    let currentCommunePostCode = await currentAdress.data.address.postcode
    southTyrolCommunities.all.map((community) => {
      if (community.plz === currentCommunePostCode) {
        currentCommune = community
      }
      return null
    })
    setStoredItem('location_community', JSON.stringify(currentCommune))
  } else {
    currentCommune = JSON.parse(getStoredItem('location_community'))
  }
  return currentCommune
}

export { clientCoordinates, isLocationInSouthTyrol, southTyrolLat, southTyrolLong, getRecommendedCommune }
