import WidgetNearMeWeatherHome from '../../../../topics/Weather/widgets/WidgetNearMeWeather/home'

const BlockNearMeWeather = ({data}) => {
  if (!data.live) {
    data.live = {
      "weatherCode": "b",
      "min": -8,
      "max": 12,
      "temperature": 6.5,
      "sunName": "Sonne",
      "sunRise": "2022-01-28T07:43:00+01:00",
      "sunSet": "2022-01-28T17:14:00+01:00",
      "moonName": "Mond",
      "moonRise": "2022-01-28T04:15:00+01:00",
      "moonSet": "2022-01-28T12:53:00+01:00",
      "stationsData": [
        {
          "id": 206,
          "category": 3,
          "name": "Signalgipfel Wilder Freiger",
          "code": "34200WS",
          "gps": { "lat": "46,9684", "lng": "11,1929", "alt": 3399 },
          "rain": 0,
          "windSpeed": 27.4,
          "windGust": 52.2,
          "windDirection": "NW",
          "humidity": "86%",
          "temperature": -17.5,
          "heatIndex": 0,
          "windChill": -28.85
        },
        {
          "id": 207,
          "category": 3,
          "name": "Ladurns",
          "code": "31810SF",
          "gps": { "lat": "46,9348", "lng": "11,3663", "alt": 1960 },
          "rain": 0,
          "windSpeed": 6.1,
          "windGust": 20.9,
          "windDirection": "NE",
          "humidity": "54%",
          "temperature": -6,
          "heatIndex": 0,
          "windChill": -8.97
        },
        {
          "id": 208,
          "category": 3,
          "name": "Pfunders Dannelspitz",
          "code": "66000WS",
          "gps": { "lat": "46,9434", "lng": "11,6871", "alt": 2808 },
          "rain": 0,
          "windSpeed": 18.4,
          "windGust": 69.5,
          "windDirection": "NW",
          "humidity": "81%",
          "temperature": -14.6,
          "heatIndex": 0,
          "windChill": -23.3
        },
        {
          "id": 209,
          "category": 3,
          "name": "Pfunders Stutzenalm",
          "code": "66100SF",
          "gps": { "lat": "46,9254", "lng": "11,6882", "alt": 2050 },
          "rain": 0,
          "windSpeed": 3.2,
          "windGust": 15.1,
          "windDirection": "W",
          "humidity": "49%",
          "temperature": -6.9,
          "heatIndex": 0,
          "windChill": -8.16
        },
        {
          "id": 600,
          "category": 3,
          "name": "Pens Tramintal",
          "code": "80100SF",
          "gps": { "lat": "46,7950", "lng": "11,4774", "alt": 2100 },
          "rain": 0,
          "windSpeed": 2.9,
          "windGust": 20.2,
          "windDirection": "NW",
          "humidity": "59%",
          "temperature": -6.6,
          "heatIndex": 0,
          "windChill": -7.57
        },
        {
          "id": 962,
          "category": 3,
          "name": "Proveis Samerberg",
          "code": "90005SF",
          "gps": { "lat": "46,4956", "lng": "10,9987", "alt": 2142 },
          "rain": 0,
          "windSpeed": 14,
          "windGust": 48.6,
          "windDirection": "NW",
          "humidity": "31%",
          "temperature": -5.1,
          "heatIndex": 0,
          "windChill": -10.48
        },
        {
          "id": 204,
          "category": 3,
          "name": "Schnals Grawand",
          "code": "15050WS",
          "gps": { "lat": "46,7703", "lng": "10,7966", "alt": 3220 },
          "rain": 0,
          "windSpeed": 38.2,
          "windGust": 50,
          "windDirection": "N",
          "humidity": "--%",
          "temperature": 0,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1287,
          "category": 3,
          "name": "Plose",
          "code": "69900MS",
          "gps": { "lat": "46,6986", "lng": "11,7338", "alt": 2472 },
          "rain": 0,
          "windSpeed": 10.4,
          "windGust": 38.9,
          "windDirection": "N",
          "humidity": "55%",
          "temperature": -9.8,
          "heatIndex": 0,
          "windChill": -15.16
        },
        {
          "id": 1299,
          "category": 3,
          "name": "Seiser Alm Zallinger",
          "code": "74200MS",
          "gps": { "lat": "46,5119", "lng": "11,6888", "alt": 2055 },
          "rain": 0,
          "windSpeed": 11.9,
          "windGust": 21.2,
          "windDirection": "NW",
          "humidity": "42%",
          "temperature": -5.2,
          "heatIndex": 0,
          "windChill": -10.07
        },
        {
          "id": 1740,
          "category": 3,
          "name": "Trafoi Zaufenkofel",
          "code": "07740WS",
          "gps": { "lat": "46,5556", "lng": "10,5397", "alt": 2475 },
          "rain": 0,
          "windSpeed": 26.3,
          "windGust": 45.4,
          "windDirection": "NW",
          "humidity": "41%",
          "temperature": -8.3,
          "heatIndex": 0,
          "windChill": -16.78
        },
        {
          "id": 1560,
          "category": 3,
          "name": "Schnals Finail",
          "code": "15590SF",
          "gps": { "lat": "46,7412", "lng": "10,8224", "alt": 1950 },
          "rain": 0,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "32%",
          "temperature": -4.2,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1721,
          "category": 3,
          "name": "Melag Pratznerberg",
          "code": "01080SF",
          "gps": { "lat": "46,8468", "lng": "10,6247", "alt": 2450 },
          "rain": 0,
          "windSpeed": 6.1,
          "windGust": 27.4,
          "windDirection": "SW",
          "humidity": "59%",
          "temperature": -10,
          "heatIndex": 0,
          "windChill": -13.58
        },
        {
          "id": 1722,
          "category": 3,
          "name": "Timmelsalm",
          "code": "20050SF",
          "gps": { "lat": "46,9136", "lng": "11,1487", "alt": 2230 },
          "rain": 0,
          "windSpeed": 22.7,
          "windGust": 43.6,
          "windDirection": "SW",
          "humidity": "47%",
          "temperature": -8.6,
          "heatIndex": 0,
          "windChill": -16.58
        },
        {
          "id": 1720,
          "category": 3,
          "name": "Ratschings Wasserfaller Alm",
          "code": "35205SF",
          "gps": { "lat": "46,8456", "lng": "11,2987", "alt": 1907 },
          "rain": 0,
          "windSpeed": 18,
          "windGust": 31.3,
          "windDirection": "E",
          "humidity": "62%",
          "temperature": -6.1,
          "heatIndex": 0,
          "windChill": -12.57
        },
        {
          "id": 1060,
          "category": 3,
          "name": "Ulten Weißbrunnspitz",
          "code": "24170WS",
          "gps": { "lat": "46,4940", "lng": "10,7740", "alt": 3253 },
          "rain": 0,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "51%",
          "temperature": -15.2,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1080,
          "category": 3,
          "name": "Weissenbach Fadner Alm",
          "code": "53200SF",
          "gps": { "lat": "46,9256", "lng": "11,8614", "alt": 2155 },
          "rain": 0,
          "windSpeed": 8.6,
          "windGust": 18,
          "windDirection": "NE",
          "humidity": "67%",
          "temperature": -8,
          "heatIndex": 0,
          "windChill": -12.37
        },
        {
          "id": 1081,
          "category": 3,
          "name": "Prags Rossalm",
          "code": "42830SF",
          "gps": { "lat": "46,6712", "lng": "12,1115", "alt": 2340 },
          "rain": 0,
          "windSpeed": 15.8,
          "windGust": 39.6,
          "windDirection": "NW",
          "humidity": "54%",
          "temperature": -8.2,
          "heatIndex": 0,
          "windChill": -14.72
        },
        {
          "id": 1291,
          "category": 1,
          "name": "St. Walburg",
          "code": "25900MS",
          "gps": { "lat": "46,5417", "lng": "10,9898", "alt": 1142 },
          "rain": 0,
          "windSpeed": 17.3,
          "windGust": 49,
          "windDirection": "S",
          "humidity": "20%",
          "temperature": 2.8,
          "heatIndex": 0,
          "windChill": -1.33
        },
        {
          "id": 1292,
          "category": 1,
          "name": "Wolkenstein",
          "code": "73500MS",
          "gps": { "lat": "46,5456", "lng": "11,7684", "alt": 1594 },
          "rain": 0,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "36%",
          "temperature": -2.2,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1293,
          "category": 1,
          "name": "Vals",
          "code": "68600MS",
          "gps": { "lat": "46,8483", "lng": "11,6272", "alt": 1410 },
          "rain": 0,
          "windSpeed": 18.7,
          "windGust": 30.2,
          "windDirection": "NW",
          "humidity": "37%",
          "temperature": -0.3,
          "heatIndex": 0,
          "windChill": -5.42
        },
        {
          "id": 1294,
          "category": 1,
          "name": "St. Valentin a.d. Haide",
          "code": "02200MS",
          "gps": { "lat": "46,7759", "lng": "10,5289", "alt": 1499 },
          "rain": 0,
          "windSpeed": 34.2,
          "windGust": 42.8,
          "windDirection": "N",
          "humidity": "37%",
          "temperature": -1,
          "heatIndex": 0,
          "windChill": -8.21
        },
        {
          "id": 1295,
          "category": 1,
          "name": "Taufers i.M.",
          "code": "03100MS",
          "gps": { "lat": "46,6373", "lng": "10,4615", "alt": 1235 },
          "rain": 0,
          "windSpeed": 6.5,
          "windGust": 22.3,
          "windDirection": "SE",
          "humidity": "23%",
          "temperature": 1.9,
          "heatIndex": 0,
          "windChill": -0.02
        },
        {
          "id": 1296,
          "category": 1,
          "name": "Toblach",
          "code": "41000MS",
          "gps": { "lat": "46,7299", "lng": "12,2191", "alt": 1219 },
          "rain": 0,
          "windSpeed": 28.4,
          "windGust": 36,
          "windDirection": "W",
          "humidity": "42%",
          "temperature": 1.2,
          "heatIndex": 0,
          "windChill": -4.74
        },
        {
          "id": 1297,
          "category": 1,
          "name": "Völs am Schlern",
          "code": "75600MS",
          "gps": { "lat": "46,5134", "lng": "11,5060", "alt": 840 },
          "rain": 0,
          "windSpeed": 7.2,
          "windGust": 17.3,
          "windDirection": "NE",
          "humidity": "19%",
          "temperature": 7.3,
          "heatIndex": 0,
          "windChill": 6.03
        },
        {
          "id": 1298,
          "category": 1,
          "name": "St. Martin in Thurn",
          "code": "62600MS",
          "gps": { "lat": "46,6792", "lng": "11,8979", "alt": 1150 },
          "rain": 0,
          "windSpeed": 7.9,
          "windGust": 16.6,
          "windDirection": "NW",
          "humidity": "29%",
          "temperature": 2.6,
          "heatIndex": 0,
          "windChill": 0.34
        },
        {
          "id": 1302,
          "category": 1,
          "name": "Hintermartell",
          "code": "11400MS",
          "gps": { "lat": "46,5169", "lng": "10,7269", "alt": 1720 },
          "rain": 0,
          "windSpeed": 11.9,
          "windGust": 27.7,
          "windDirection": "S",
          "humidity": "24%",
          "temperature": -2.3,
          "heatIndex": 0,
          "windChill": -6.56
        },
        {
          "id": 1303,
          "category": 1,
          "name": "Marienberg",
          "code": "02500MS",
          "gps": { "lat": "46,7057", "lng": "10,5213", "alt": 1310 },
          "rain": 0,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "23%",
          "temperature": 2.4,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1304,
          "category": 1,
          "name": "Pfitsch St. Jakob",
          "code": "33500MS",
          "gps": { "lat": "46,9656", "lng": "11,5981", "alt": 1440 },
          "rain": 0,
          "windSpeed": 16.6,
          "windGust": 32.8,
          "windDirection": "SE",
          "humidity": "44%",
          "temperature": -1.6,
          "heatIndex": 0,
          "windChill": -6.69
        },
        {
          "id": 1305,
          "category": 1,
          "name": "St. Magdalena in Gsies",
          "code": "44500MS",
          "gps": { "lat": "46,8353", "lng": "12,2427", "alt": 1412 },
          "rain": 0,
          "windSpeed": 6.5,
          "windGust": 9.4,
          "windDirection": "NE",
          "humidity": "51%",
          "temperature": -1.8,
          "heatIndex": 0,
          "windChill": -4.3
        },
        {
          "id": 1306,
          "category": 1,
          "name": "Sulden",
          "code": "06400MS",
          "gps": { "lat": "46,5159", "lng": "10,5953", "alt": 1907 },
          "rain": 0,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "29%",
          "temperature": -4.4,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1307,
          "category": 1,
          "name": "Terenten",
          "code": "65600MS",
          "gps": { "lat": "46,8345", "lng": "11,7856", "alt": 1349 },
          "rain": 0,
          "windSpeed": 21.2,
          "windGust": 40.3,
          "windDirection": "W",
          "humidity": "30%",
          "temperature": 1.1,
          "heatIndex": 0,
          "windChill": -4.02
        },
        {
          "id": 1308,
          "category": 1,
          "name": "Ulten Weißbrunn",
          "code": "24400MS",
          "gps": { "lat": "46,4868", "lng": "10,8318", "alt": 1900 },
          "rain": 0,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "21%",
          "temperature": -2.9,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1309,
          "category": 1,
          "name": "Welsberg",
          "code": "43200MS",
          "gps": { "lat": "46,7431", "lng": "12,1289", "alt": 1131 },
          "rain": 0,
          "windSpeed": 13.3,
          "windGust": 30.6,
          "windDirection": "W",
          "humidity": "36%",
          "temperature": 2.5,
          "heatIndex": 0,
          "windChill": -1.03
        },
        {
          "id": 1310,
          "category": 1,
          "name": "Welschnofen",
          "code": "78305MS",
          "gps": { "lat": "46,4289", "lng": "11,5366", "alt": 1128 },
          "rain": 0,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "25%",
          "temperature": 2.8,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1311,
          "category": 1,
          "name": "Mühlwald",
          "code": "56500MS",
          "gps": { "lat": "46,8901", "lng": "11,8744", "alt": 1141 },
          "rain": 0,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "50%",
          "temperature": 1,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1312,
          "category": 1,
          "name": "St. Veit in Prags",
          "code": "42700MS",
          "gps": { "lat": "46,7119", "lng": "12,1000", "alt": 1285 },
          "rain": 0,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "38%",
          "temperature": 0.1,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1313,
          "category": 1,
          "name": "Schnals Vernagt",
          "code": "15800MS",
          "gps": { "lat": "46,7357", "lng": "10,8493", "alt": 1700 },
          "rain": 0,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "29%",
          "temperature": -1.7,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1820,
          "category": 1,
          "name": "Obervintl",
          "code": "65350MS",
          "gps": { "lat": "46,8111", "lng": "11,7534", "alt": 746 },
          "rain": 0,
          "windSpeed": 17.6,
          "windGust": 31,
          "windDirection": "SW",
          "humidity": "23%",
          "temperature": 5.3,
          "heatIndex": 0,
          "windChill": 1.75
        },
        {
          "id": 1723,
          "category": 1,
          "name": "Jenesien",
          "code": "82910MS",
          "gps": { "lat": "46,5304", "lng": "11,3319", "alt": 970 },
          "rain": 0,
          "windSpeed": 10.8,
          "windGust": 27.7,
          "windDirection": "N",
          "humidity": "18%",
          "temperature": 5.7,
          "heatIndex": 0,
          "windChill": 3.33
        },
        {
          "id": 1800,
          "category": 1,
          "name": "Gargazon",
          "code": "27100MS",
          "gps": { "lat": "46,5767", "lng": "11,2008", "alt": 254 },
          "rain": 0,
          "windSpeed": 23,
          "windGust": 34.6,
          "windDirection": "N",
          "humidity": "14%",
          "temperature": 12.5,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1220,
          "category": 1,
          "name": "Branzoll",
          "code": "85700MS",
          "gps": { "lat": "46,4065", "lng": "11,3111", "alt": 226 },
          "rain": 0,
          "windSpeed": 2.2,
          "windGust": 9.4,
          "windDirection": "S",
          "humidity": "83%",
          "temperature": 2.1,
          "heatIndex": 0,
          "windChill": 2.47
        },
        {
          "id": 2020,
          "category": 1,
          "name": "Radein",
          "code": "87300MS",
          "gps": { "lat": "46,3465", "lng": "11,3968", "alt": 1551 },
          "rain": 0,
          "windSpeed": 7.9,
          "windGust": 22.7,
          "windDirection": "NW",
          "humidity": "22%",
          "temperature": 0.9,
          "heatIndex": 0,
          "windChill": -1.65
        },
        {
          "id": 2060,
          "category": 1,
          "name": "Pfunders",
          "code": "66500MS",
          "gps": { "lat": "46,8872", "lng": "11,7029", "alt": 1168 },
          "rain": 0,
          "windSpeed": 11.2,
          "windGust": 49,
          "windDirection": "NW",
          "humidity": "31%",
          "temperature": 2.2,
          "heatIndex": 0,
          "windChill": -0.96
        },
        {
          "id": 2080,
          "category": 1,
          "name": "Platt in Passeier",
          "code": "20910MS",
          "gps": { "lat": "46,8215", "lng": "11,1762", "alt": 1145 },
          "rain": 0,
          "windSpeed": 9,
          "windGust": 25.9,
          "windDirection": "NW",
          "humidity": "27%",
          "temperature": 2.4,
          "heatIndex": 0,
          "windChill": -0.2
        },
        {
          "id": 2100,
          "category": 1,
          "name": "Pflersch",
          "code": "31410MS",
          "gps": { "lat": "46,9557", "lng": "11,3793", "alt": 1154 },
          "rain": 1.8,
          "windSpeed": 9.4,
          "windGust": 20.5,
          "windDirection": "SE",
          "humidity": "55%",
          "temperature": 1.1,
          "heatIndex": 0,
          "windChill": -1.84
        },
        {
          "id": 2120,
          "category": 1,
          "name": "Karerpass",
          "code": "78110MS",
          "gps": { "lat": "46,4092", "lng": "11,6017", "alt": 1715 },
          "rain": 0,
          "windSpeed": 19.1,
          "windGust": 32,
          "windDirection": "W",
          "humidity": "29%",
          "temperature": -1.3,
          "heatIndex": 0,
          "windChill": -6.74
        },
        {
          "id": 2240,
          "category": 1,
          "name": "Corvara",
          "code": "61300MS",
          "gps": { "lat": "46,5504", "lng": "11,8733", "alt": 1530 },
          "rain": 0,
          "windSpeed": 10.8,
          "windGust": 26.6,
          "windDirection": "NW",
          "humidity": "29%",
          "temperature": -0.4,
          "heatIndex": 0,
          "windChill": -4
        },
        {
          "id": 2180,
          "category": 1,
          "name": "Grub - Langtaufers",
          "code": "01110MS",
          "gps": { "lat": "46,8365", "lng": "10,633", "alt": 1842 },
          "rain": 0,
          "windSpeed": 5,
          "windGust": 38.5,
          "windDirection": "SW",
          "humidity": "41%",
          "temperature": -4.2,
          "heatIndex": 0,
          "windChill": -6.35
        },
        {
          "id": 2200,
          "category": 1,
          "name": "St. Peter Villnöss",
          "code": "71510MS",
          "gps": { "lat": "46,6479", "lng": "11,6894", "alt": 1327 },
          "rain": 0,
          "windSpeed": 4.3,
          "windGust": 10.8,
          "windDirection": "NW",
          "humidity": "28%",
          "temperature": 2,
          "heatIndex": 0,
          "windChill": 1.01
        },
        {
          "id": 2220,
          "category": 1,
          "name": "Siffian - Ritten",
          "code": "79300MS",
          "gps": { "lat": "46,529", "lng": "11,4638", "alt": 987 },
          "rain": 0,
          "windSpeed": 16.6,
          "windGust": 24.5,
          "windDirection": "NE",
          "humidity": "23%",
          "temperature": 4.9,
          "heatIndex": 0,
          "windChill": 1.39
        },
        {
          "id": 2040,
          "category": 1,
          "name": "Sexten",
          "code": "91510MS",
          "gps": { "lat": "46,6915", "lng": "12,3577", "alt": 1322 },
          "rain": 0,
          "windSpeed": 22.7,
          "windGust": 42.8,
          "windDirection": "NW",
          "humidity": "38%",
          "temperature": 0.8,
          "heatIndex": 0,
          "windChill": -4.6
        },
        {
          "id": 1900,
          "category": 2,
          "name": "ETSCH BEI SALURN",
          "code": "89940PG",
          "gps": { "lat": "46,243333", "lng": "11,20262", "alt": 210 },
          "water": { "level": 134, "temperature": 3, "flow": 53.6 }
        },
        {
          "id": 1940,
          "category": 2,
          "name": "ETSCH BEI EYRS",
          "code": "08155PG",
          "gps": { "lat": "46,621876", "lng": "10,64963", "alt": 873.99 },
          "water": { "level": 64, "temperature": 0, "flow": 4.5 }
        },
        {
          "id": 122,
          "category": 2,
          "name": "ETSCH BEI TÖLL",
          "code": "19850PG",
          "gps": { "lat": "46,67202", "lng": "11,07961", "alt": 506.34 },
          "water": { "level": 139, "temperature": 4, "flow": 21.2 }
        },
        {
          "id": 124,
          "category": 2,
          "name": "ETSCH BEI SIGMUNDSKRON",
          "code": "29850PG",
          "gps": { "lat": "46,48316", "lng": "11,30381", "alt": 237.04 },
          "water": { "level": 152, "temperature": 3, "flow": 22.2 }
        },
        {
          "id": 125,
          "category": 2,
          "name": "MAREITERBACH BEI STERZING",
          "code": "36750PG",
          "gps": { "lat": "46,88395", "lng": "11,43143", "alt": 939.59 },
          "water": { "level": 97, "temperature": 0, "flow": 2.2 }
        },
        {
          "id": 127,
          "category": 2,
          "name": "AHR BEI ST.GEORGEN",
          "code": "59450PG",
          "gps": { "lat": "46,81704", "lng": "11,93472", "alt": 815.77 },
          "water": { "level": 76, "temperature": 1, "flow": 5.3 }
        },
        {
          "id": 128,
          "category": 2,
          "name": "GADER BEI MONTAL",
          "code": "64550PG",
          "gps": { "lat": "46,77610", "lng": "11,87967", "alt": 813.27 },
          "water": { "level": 101, "temperature": 0, "flow": 3.5 }
        },
        {
          "id": 129,
          "category": 2,
          "name": "RIENZ BEI VINTL",
          "code": "67350PG",
          "gps": { "lat": "46,81555", "lng": "11,70816", "alt": 733.19 },
          "water": { "level": 54, "temperature": 2, "flow": 21.8 }
        },
        {
          "id": 130,
          "category": 2,
          "name": "EISACK BEI BRIXEN",
          "code": "69790PG",
          "gps": { "lat": "46,70738", "lng": "11,65720", "alt": 551.54 },
          "water": { "level": 112, "temperature": 0, "flow": 31.4 }
        },
        {
          "id": 133,
          "category": 2,
          "name": "ETSCH BEI BRANZOLL",
          "code": "85550PG",
          "gps": { "lat": "46,41368", "lng": "11,31525", "alt": 226.05 },
          "water": { "level": 115, "temperature": 2, "flow": 63.1 }
        },
        {
          "id": 140,
          "category": 2,
          "name": "ETSCH BEI SPONDINIG",
          "code": "05950PG",
          "gps": { "lat": "46,63648", "lng": "10,60352", "alt": 882.34 },
          "water": { "level": 28, "temperature": 2, "flow": 4.1 }
        },
        {
          "id": 521,
          "category": 2,
          "name": "EISACK BEI FREIENFELD",
          "code": "37230PG",
          "gps": { "lat": "46,87070", "lng": "11,47787", "alt": 927.36 },
          "water": { "level": 95, "temperature": 7, "flow": 5.2 }
        },
        {
          "id": 620,
          "category": 2,
          "name": "RIENZ BEI STEGEN",
          "code": "61050PG",
          "gps": { "lat": "46,79152", "lng": "11,92169", "alt": 808.71 },
          "water": { "level": 143, "temperature": 2, "flow": 19 }
        },
        {
          "id": 980,
          "category": 2,
          "name": "TALFER BEI BOZEN",
          "code": "82910PG",
          "gps": { "lat": "46,50977", "lng": "11,35018", "alt": 287.71 },
          "water": { "level": 26, "temperature": 3, "flow": 4.4 }
        },
        {
          "id": 520,
          "category": 2,
          "name": "GRÖDNERBACH BEI PONTIVES",
          "code": "73950PG",
          "gps": { "lat": "46,58271", "lng": "11,63623", "alt": 1190 },
          "water": { "level": 23, "temperature": 2, "flow": 2.5 }
        },
        {
          "id": 1421,
          "category": 2,
          "name": "PASSER BEI MERAN",
          "code": "22950PG",
          "gps": { "lat": "46,68396", "lng": "11,17721", "alt": 355.88 },
          "water": { "level": 19, "temperature": 3, "flow": 3.2 }
        },
        {
          "id": 1422,
          "category": 2,
          "name": "RAMBACH BEI LAATSCH",
          "code": "03750PG",
          "gps": { "lat": "46,67082", "lng": "10,52390", "alt": 942.14 },
          "water": { "level": 17, "temperature": 2, "flow": 1.1 }
        },
        {
          "id": 1423,
          "category": 2,
          "name": "PRAGSERBACH BEI AUSSERPRAGS",
          "code": "42850PG",
          "gps": { "lat": "46,73912", "lng": "12,13444", "alt": 1122.81 },
          "water": { "level": 15, "temperature": 0, "flow": 0.9 }
        },
        {
          "id": 1424,
          "category": 2,
          "name": "AHR BEI STEINHAUS",
          "code": "51450PG",
          "gps": { "lat": "46,99459", "lng": "11,98027", "alt": 1044.78 },
          "water": { "level": 48, "temperature": 2, "flow": 2 }
        },
        {
          "id": 1425,
          "category": 2,
          "name": "PFELDERERBACH BEI ESCHBAUM",
          "code": "20750PG",
          "gps": { "lat": "46,80145", "lng": "11,10271", "alt": 1569.26 },
          "water": { "level": 28, "temperature": 1, "flow": 0.4 }
        },
        {
          "id": 1426,
          "category": 2,
          "name": "FALSCHAUER BEI LANA",
          "code": "26950PG",
          "gps": { "lat": "46,62764", "lng": "11,16523", "alt": 270.5 },
          "water": { "level": 89, "temperature": 3, "flow": 10.2 }
        },
        {
          "id": 1427,
          "category": 2,
          "name": "DRAU BEI VIERSCHACH",
          "code": "91850PG",
          "gps": { "lat": "46,73355", "lng": "12,33783", "alt": 1127.32 },
          "water": { "level": 26, "temperature": 0, "flow": 1.8 }
        },
        {
          "id": 1683,
          "category": 2,
          "name": "AHR BEI KEMATEN",
          "code": "57150PG",
          "gps": { "lat": "46,9035", "lng": "11,9561", "alt": 0 },
          "water": { "level": 65, "temperature": 2, "flow": 2 }
        },
        {
          "id": 1400,
          "category": 2,
          "name": "EISACK BEI BOZEN SÜD",
          "code": "83450PG",
          "gps": { "lat": "46,45877", "lng": "11,30278", "alt": 235.03 },
          "water": { "level": 125, "temperature": 2, "flow": 41.2 }
        },
        {
          "id": 1585,
          "category": 2,
          "name": "EISACK BEI STERZING",
          "code": "32470PG",
          "gps": { "lat": "46,892976", "lng": "11,442841", "alt": 942 },
          "water": { "level": 45, "temperature": 4, "flow": 0 }
        },
        {
          "id": 800,
          "category": 3,
          "name": "Gsies Pfinnalm",
          "code": "45200SF",
          "gps": { "lat": "46,8417", "lng": "12,2096", "alt": 2152 },
          "rain": 0,
          "windSpeed": 2.2,
          "windGust": 20.2,
          "windDirection": "E",
          "humidity": "80%",
          "temperature": -8.2,
          "heatIndex": 0,
          "windChill": -8.56
        },
        {
          "id": 105,
          "category": 3,
          "name": "Rittnerhorn",
          "code": "82500WS",
          "gps": { "lat": "46,6156", "lng": "11,4604", "alt": 2260 },
          "rain": 0,
          "windSpeed": 15.1,
          "windGust": 24.8,
          "windDirection": "NW",
          "humidity": "40%",
          "temperature": -7.7,
          "heatIndex": 0,
          "windChill": -13.93
        },
        {
          "id": 820,
          "category": 3,
          "name": "Gsies Regelspitze",
          "code": "45100WS",
          "gps": { "lat": "46,8561", "lng": "12,2005", "alt": 2747 },
          "rain": 0,
          "windSpeed": 7.6,
          "windGust": 22.7,
          "windDirection": "W",
          "humidity": "83%",
          "temperature": -12.9,
          "heatIndex": 0,
          "windChill": -17.7
        },
        {
          "id": 112,
          "category": 3,
          "name": "Graun Elferspitze",
          "code": "00700WS",
          "gps": { "lat": "46,7767", "lng": "10,4878", "alt": 2926 },
          "rain": 0,
          "windSpeed": 46.4,
          "windGust": 78.5,
          "windDirection": "NW",
          "humidity": "67%",
          "temperature": -15,
          "heatIndex": 0,
          "windChill": -28.2
        },
        {
          "id": 113,
          "category": 3,
          "name": "Graun Schöneben",
          "code": "00390SF",
          "gps": { "lat": "46,8064", "lng": "10,4979", "alt": 2040 },
          "rain": 0,
          "windSpeed": 3.2,
          "windGust": 13.3,
          "windDirection": "W",
          "humidity": "39%",
          "temperature": -6.7,
          "heatIndex": 0,
          "windChill": -7.94
        },
        {
          "id": 114,
          "category": 3,
          "name": "Sulden Schöntaufspitze",
          "code": "06040WS",
          "gps": { "lat": "46,5029", "lng": "10,6286", "alt": 3328 },
          "rain": 0,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "--%",
          "temperature": 0,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 115,
          "category": 3,
          "name": "Sulden Madritsch",
          "code": "06090SF",
          "gps": { "lat": "46,4938", "lng": "10,6144", "alt": 2825 },
          "rain": 0,
          "windSpeed": 28.1,
          "windGust": 48.2,
          "windDirection": "W",
          "humidity": "44%",
          "temperature": -12.7,
          "heatIndex": 0,
          "windChill": -22.75
        },
        {
          "id": 117,
          "category": 3,
          "name": "Jaufenkamm",
          "code": "35100WS",
          "gps": { "lat": "46,8401", "lng": "11,3184", "alt": 2145 },
          "rain": 0,
          "windSpeed": 7.6,
          "windGust": 15.5,
          "windDirection": "E",
          "humidity": "89%",
          "temperature": -7.5,
          "heatIndex": 0,
          "windChill": -11.38
        },
        {
          "id": 118,
          "category": 3,
          "name": "Prettau Lengspitze",
          "code": "50400WS",
          "gps": { "lat": "47,0159", "lng": "12,1310", "alt": 3105 },
          "rain": 0,
          "windSpeed": 6.8,
          "windGust": 39.6,
          "windDirection": "NW",
          "humidity": "--%",
          "temperature": -16,
          "heatIndex": 0,
          "windChill": -20.9
        },
        {
          "id": 119,
          "category": 3,
          "name": "Prettau Merbalm",
          "code": "50360SF",
          "gps": { "lat": "47,0362", "lng": "12,1221", "alt": 2006 },
          "rain": 0,
          "windSpeed": 6.5,
          "windGust": 24.5,
          "windDirection": "SE",
          "humidity": "71%",
          "temperature": -7.8,
          "heatIndex": 0,
          "windChill": -11.24
        },
        {
          "id": 120,
          "category": 3,
          "name": "Abtei Piz La Ila",
          "code": "61690SF",
          "gps": { "lat": "46,5650", "lng": "11,9036", "alt": 2050 },
          "rain": 0,
          "windSpeed": 2.9,
          "windGust": 11.5,
          "windDirection": "N",
          "humidity": "38%",
          "temperature": -6.1,
          "heatIndex": 0,
          "windChill": -7.02
        },
        {
          "id": 121,
          "category": 3,
          "name": "Abtei Piz Pisciadù",
          "code": "61720WS",
          "gps": { "lat": "46,5314", "lng": "11,8250", "alt": 2985 },
          "rain": 0,
          "windSpeed": 44.3,
          "windGust": 82.8,
          "windDirection": "W",
          "humidity": "56%",
          "temperature": -14.5,
          "heatIndex": 0,
          "windChill": -27.29
        },
        {
          "id": 135,
          "category": 3,
          "name": "Pfelders Rauhjoch",
          "code": "20720WS",
          "gps": { "lat": "46,8289", "lng": "11,1146", "alt": 2926 },
          "rain": 0,
          "windSpeed": 47.5,
          "windGust": 80.6,
          "windDirection": "NW",
          "humidity": "68%",
          "temperature": -13.8,
          "heatIndex": 0,
          "windChill": -26.69
        },
        {
          "id": 136,
          "category": 3,
          "name": "Pfelders Grünboden",
          "code": "20690SF",
          "gps": { "lat": "46,7920", "lng": "11,1016", "alt": 2015 },
          "rain": 0,
          "windSpeed": 1.8,
          "windGust": 10.1,
          "windDirection": "SW",
          "humidity": "33%",
          "temperature": -5,
          "heatIndex": 0,
          "windChill": -4.66
        },
        {
          "id": 200,
          "category": 3,
          "name": "Toblach Hochebenkofel",
          "code": "91210WS",
          "gps": { "lat": "46,6802", "lng": "12,2573", "alt": 2906 },
          "rain": 0,
          "windSpeed": 19.4,
          "windGust": 56.5,
          "windDirection": "NW",
          "humidity": "67%",
          "temperature": -13.5,
          "heatIndex": 0,
          "windChill": -22.15
        },
        {
          "id": 201,
          "category": 3,
          "name": "Sexten Rotwandwiesen",
          "code": "91180SF",
          "gps": { "lat": "46,6636", "lng": "12,3704", "alt": 1910 },
          "rain": 0,
          "windSpeed": 8.6,
          "windGust": 25.9,
          "windDirection": "W",
          "humidity": "39%",
          "temperature": -4.5,
          "heatIndex": 0,
          "windChill": -8.24
        },
        {
          "id": 202,
          "category": 3,
          "name": "Ulten Rossbänke",
          "code": "24300SF",
          "gps": { "lat": "46,4693", "lng": "10,8194", "alt": 2255 },
          "rain": 0,
          "windSpeed": 14.4,
          "windGust": 46.8,
          "windDirection": "NE",
          "humidity": "22%",
          "temperature": -6.8,
          "heatIndex": 0,
          "windChill": -12.66
        },
        {
          "id": 821,
          "category": 3,
          "name": "Deutschnofen Obereggen",
          "code": "78480SF",
          "gps": { "lat": "46,3636", "lng": "11,5467", "alt": 2125 },
          "rain": 0,
          "windSpeed": 4.3,
          "windGust": 25.6,
          "windDirection": "E",
          "humidity": "30%",
          "temperature": -4.8,
          "heatIndex": 0,
          "windChill": -6.63
        },
        {
          "id": 205,
          "category": 3,
          "name": "Schnals Teufelsegg",
          "code": "15000SF",
          "gps": { "lat": "46,7847", "lng": "10,7647", "alt": 3035 },
          "rain": 0,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "70%",
          "temperature": -15,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1202,
          "category": 1,
          "name": "Sterzing",
          "code": "37100MS",
          "gps": { "lat": "46,8850", "lng": "11,4386", "alt": 943 },
          "rain": 0,
          "windSpeed": 13,
          "windGust": 32,
          "windDirection": "N",
          "humidity": "39%",
          "temperature": 3.6,
          "heatIndex": 0,
          "windChill": 0.37
        },
        {
          "id": 1280,
          "category": 1,
          "name": "Pfelders",
          "code": "20500MS",
          "gps": { "lat": "46,7965", "lng": "11,0883", "alt": 1640 },
          "rain": 0,
          "windSpeed": 7.9,
          "windGust": 24.5,
          "windDirection": "SW",
          "humidity": "32%",
          "temperature": -1.9,
          "heatIndex": 0,
          "windChill": -4.94
        },
        {
          "id": 1281,
          "category": 1,
          "name": "Naturns",
          "code": "19300MS",
          "gps": { "lat": "46,6477", "lng": "10,9912", "alt": 541 },
          "rain": 0,
          "windSpeed": 22.3,
          "windGust": 38.9,
          "windDirection": "SW",
          "humidity": "15%",
          "temperature": 9.3,
          "heatIndex": 0,
          "windChill": 6.27
        },
        {
          "id": 1282,
          "category": 1,
          "name": "Pens",
          "code": "80300MS",
          "gps": { "lat": "46,7799", "lng": "11,4144", "alt": 1450 },
          "rain": 0.1,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "50%",
          "temperature": -1.6,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1283,
          "category": 1,
          "name": "Prettau",
          "code": "50500MS",
          "gps": { "lat": "47,0366", "lng": "12,0991", "alt": 1450 },
          "rain": 0.4,
          "windSpeed": 31.3,
          "windGust": 66.2,
          "windDirection": "SE",
          "humidity": "59%",
          "temperature": -2.6,
          "heatIndex": 0,
          "windChill": -10.01
        },
        {
          "id": 1284,
          "category": 1,
          "name": "Ridnaun",
          "code": "34500MS",
          "gps": { "lat": "46,9091", "lng": "11,3068", "alt": 1364 },
          "rain": 0.2,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "55%",
          "temperature": 0.1,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1285,
          "category": 1,
          "name": "Sand i.T. Mühlen",
          "code": "56900MS",
          "gps": { "lat": "46,9003", "lng": "11,9474", "alt": 851 },
          "rain": 0,
          "windSpeed": 9,
          "windGust": 16.2,
          "windDirection": "NW",
          "humidity": "42%",
          "temperature": 3.6,
          "heatIndex": 0,
          "windChill": 1.23
        },
        {
          "id": 1286,
          "category": 1,
          "name": "Rein in Taufers",
          "code": "54500MS",
          "gps": { "lat": "46,9428", "lng": "12,0825", "alt": 1562 },
          "rain": 0.1,
          "windSpeed": 7.9,
          "windGust": 27.4,
          "windDirection": "SE",
          "humidity": "47%",
          "temperature": -2.8,
          "heatIndex": 0,
          "windChill": -5.99
        },
        {
          "id": 1288,
          "category": 1,
          "name": "Schlanders",
          "code": "09700MS",
          "gps": { "lat": "46,6238", "lng": "10,7803", "alt": 698 },
          "rain": 0,
          "windSpeed": 29.2,
          "windGust": 51.1,
          "windDirection": "W",
          "humidity": "16%",
          "temperature": 8.8,
          "heatIndex": 0,
          "windChill": 5.07
        },
        {
          "id": 1289,
          "category": 1,
          "name": "Sarnthein",
          "code": "82200MS",
          "gps": { "lat": "46,6406", "lng": "11,3551", "alt": 970 },
          "rain": 0,
          "windSpeed": 18.7,
          "windGust": 44.6,
          "windDirection": "NW",
          "humidity": "16%",
          "temperature": 5.8,
          "heatIndex": 0,
          "windChill": 2.23
        },
        {
          "id": 1290,
          "category": 1,
          "name": "Salurn",
          "code": "88820MS",
          "gps": { "lat": "46,2362", "lng": "11,1855", "alt": 212 },
          "rain": 0,
          "windSpeed": 18.7,
          "windGust": 24.1,
          "windDirection": "E",
          "humidity": "25%",
          "temperature": 7.3,
          "heatIndex": 0,
          "windChill": 4.12
        },
        {
          "id": 1240,
          "category": 1,
          "name": "Bruneck",
          "code": "59700MS",
          "gps": { "lat": "46,8043", "lng": "11,9315", "alt": 828 },
          "rain": 0,
          "windSpeed": 27,
          "windGust": 34.2,
          "windDirection": "NE",
          "humidity": "39%",
          "temperature": 4,
          "heatIndex": 0,
          "windChill": -0.97
        },
        {
          "id": 1241,
          "category": 1,
          "name": "Deutschnofen",
          "code": "85120MS",
          "gps": { "lat": "46,4205", "lng": "11,4269", "alt": 1470 },
          "rain": 0,
          "windSpeed": 27.4,
          "windGust": 38.9,
          "windDirection": "N",
          "humidity": "23%",
          "temperature": 0.5,
          "heatIndex": 0,
          "windChill": -5.54
        },
        {
          "id": 1242,
          "category": 1,
          "default": true,
          "name": "Bozen",
          "code": "83200MS",
          "gps": { "lat": "46,4977", "lng": "11,3128", "alt": 254 },
          "rain": 0,
          "windSpeed": 5.8,
          "windGust": 13.3,
          "windDirection": "W",
          "humidity": "14%",
          "temperature": 12.4,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1243,
          "category": 1,
          "name": "Auer",
          "code": "86900MS",
          "gps": { "lat": "46,3469", "lng": "11,3050", "alt": 250 },
          "rain": 0,
          "windSpeed": 0,
          "windGust": 0,
          "windDirection": "--",
          "humidity": "51%",
          "temperature": 2.6,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1244,
          "category": 1,
          "name": "Brixen Vahrn",
          "code": "39100MS",
          "gps": { "lat": "46,7305", "lng": "11,6442", "alt": 590 },
          "rain": 0,
          "windSpeed": 13,
          "windGust": 22.7,
          "windDirection": "N",
          "humidity": "21%",
          "temperature": 8.9,
          "heatIndex": 0,
          "windChill": 6.83
        },
        {
          "id": 1245,
          "category": 1,
          "name": "Laas - Eyrs",
          "code": "08200MS",
          "gps": { "lat": "46,6249", "lng": "10,6496", "alt": 874 },
          "rain": 0,
          "windSpeed": 20.9,
          "windGust": 34.2,
          "windDirection": "W",
          "humidity": "18%",
          "temperature": 6.8,
          "heatIndex": 0,
          "windChill": 3.24
        },
        {
          "id": 1246,
          "category": 1,
          "name": "Meran",
          "code": "23200MS",
          "gps": { "lat": "46,6880", "lng": "11,1366", "alt": 330 },
          "rain": 0,
          "windSpeed": 24.8,
          "windGust": 48.2,
          "windDirection": "SW",
          "humidity": "13%",
          "temperature": 11.9,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1260,
          "category": 1,
          "name": "Antholz Obertal",
          "code": "47400MS",
          "gps": { "lat": "46,8704", "lng": "12,1166", "alt": 1320 },
          "rain": 0,
          "windSpeed": 14.4,
          "windGust": 22,
          "windDirection": "NE",
          "humidity": "48%",
          "temperature": -0.6,
          "heatIndex": 0,
          "windChill": -5.04
        },
        {
          "id": 1261,
          "category": 1,
          "name": "Laimburg",
          "code": "86600MS",
          "gps": { "lat": "46,3825", "lng": "11,2887", "alt": 224 },
          "rain": 0,
          "windSpeed": 1.1,
          "windGust": 9.7,
          "windDirection": "SE",
          "humidity": "87%",
          "temperature": 3,
          "heatIndex": 0,
          "windChill": 4.65
        },
        {
          "id": 1262,
          "category": 1,
          "name": "Franzensfeste Grasstein",
          "code": "37700MS",
          "gps": { "lat": "46,8125", "lng": "11,5488", "alt": 850 },
          "rain": 0,
          "windSpeed": 27.4,
          "windGust": 43.6,
          "windDirection": "SW",
          "humidity": "33%",
          "temperature": 4.7,
          "heatIndex": 0,
          "windChill": -0.1
        },
        {
          "id": 1266,
          "category": 1,
          "name": "Barbian Kollmann",
          "code": "74900MS",
          "gps": { "lat": "46,5951", "lng": "11,5291", "alt": 490 },
          "rain": 0,
          "windSpeed": 1.4,
          "windGust": 6.1,
          "windDirection": "SW",
          "humidity": "56%",
          "temperature": 1.5,
          "heatIndex": 0,
          "windChill": 2.68
        },
        {
          "id": 1428,
          "category": 1,
          "name": "Kaltern Oberplanitzing",
          "code": "89190MS",
          "gps": { "lat": "46,4423", "lng": "11,2525", "alt": 495 },
          "rain": 0,
          "windSpeed": 12.6,
          "windGust": 27,
          "windDirection": "NE",
          "humidity": "16%",
          "temperature": 10.3,
          "heatIndex": 0,
          "windChill": 0
        },
        {
          "id": 1429,
          "category": 1,
          "name": "St. Martin in Passeier",
          "code": "22210MS",
          "gps": { "lat": "46,7824", "lng": "11,2296", "alt": 586 },
          "rain": 0,
          "windSpeed": 20.5,
          "windGust": 43.9,
          "windDirection": "NE",
          "humidity": "19%",
          "temperature": 7.5,
          "heatIndex": 0,
          "windChill": 4.17
        }
      ]
    }
  }
  return <WidgetNearMeWeatherHome data={data} />
}

export default BlockNearMeWeather
