import React, { useState } from 'react'
import styled from 'styled-components'
import translate from '../../../../../../modules/translate'
import Text from '../../../../../../components/Text'
import SkeletonLiveData from '../../../../components/LiveData/skeleton'
import ForecastPrognosisSkeleton from '../../../../components/Forecast/views/ForecastPrognosis/views/ForecastPrognosisSkeleton'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import isStolApp from '../../../../../../modules/is-stolapp'
import isAppVersionReady from '../../../../../../modules/is-app-version-ready'

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.font.primary};
`

const ReloadLocationButton = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  padding: 15px 35px;
  cursor: pointer;
  background-color: ${(props) => props.theme.color.stol};
  border: unset;
  text-align: center;
  border-radius: 4px;
  color: white;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
`

const ReloadLocationLink = styled.a`
  display: block;
  text-decoration: none;
  font-family: ${(props) => props.theme.font.primary};
  padding: 15px 35px;
  cursor: pointer;
  background-color: ${(props) => props.theme.color.stol};
  border: unset;
  text-align: center;
  border-radius: 4px;
  color: white;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
`

const StyledText = styled(Text)`
  padding: 20px;
  font-size: ${(props) => props.theme.fontSize.small};
  text-align: center;
`
const StyledBox = styled(Box)`
  position: absolute;
  z-index: 9000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  background-color: #eee;
  width: 300px;
  padding: 15px;
  font-family: ${(props) => props.theme.font.primary};
  @media (max-width: 800px) {
    left: calc(100% - 50%);
  }
  border: none;
  text-align: center;
  padding-bottom: 25px;
`

const CrossDiv = styled.div`
  position: absolute;
  top: -26px;
  right: -27px;
  opacity: 0.8;
  color: #afafaf;
  font-size: 30px;
  width: 18px;

  margin-left: auto;
  :after {
    background-color: #fff;

    content: '';
    display: block;
    height: 2px;
    margin: 7px 0;

    transform: translateY(-4px) rotate(135deg);
    :hover {
      background-color: #d41e25;
    }
  }

  :before {
    background-color: #fff;

    content: '';
    display: block;
    height: 2px;
    margin: 7px 0;

    transform: translateY(5px) rotate(-135deg);
    :hover {
      background-color: #d41e25;
    }
  }

  cursor: pointer;
  @media (max-width: 800px) {
    top: -40px;
    right: 19px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    top: -40px;
    right: 10px;
  }
`

const ScreenNoGeoLocation = ({ run, ...props }) => {
  const [modalOpen, setModalOpen] = useState(true)

  let settingsNotificationWeb = translate('weather_geo_notification_web_settings')
  let settingsNotificationApp = translate('weather_geo_notification_app_settings')

  function handleClose(e) {
    setModalOpen(false)
  }
  return (
    <Wrapper {...props}>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={handleClose}
        {...props}
      >
        <StyledBox>
          <StyledText>{translate('weather_geo_notification')}</StyledText>{' '}
          {!isStolApp() && (
            <ReloadLocationButton onClick={handleClose}>
              {translate('weather_geo_notification_accept')}
            </ReloadLocationButton>
          )}{' '}
          {isStolApp() && (
            <div>
              {!isAppVersionReady('4.13', '4.8.7') && (
                <ReloadLocationButton onClick={handleClose}>
                  {translate('weather_geo_notification_accept')}
                </ReloadLocationButton>
              )}
              {isAppVersionReady('4.13', '4.8.7') && (
                <ReloadLocationLink href={'/native-app/show/geosettings'}>
                  {' '}
                  {translate('weather_geo_notification_open_app_settings')}
                </ReloadLocationLink>
              )}
            </div>
          )}
          <CrossDiv onClick={handleClose}></CrossDiv>{' '}
        </StyledBox>
      </Modal>
      <StyledText>{isStolApp() ? settingsNotificationApp : settingsNotificationWeb}</StyledText>
      <SkeletonLiveData
        onClick={() => {
          setModalOpen(true)
        }}
      ></SkeletonLiveData>

      <ForecastPrognosisSkeleton
        onClick={() => {
          setModalOpen(true)
        }}
      ></ForecastPrognosisSkeleton>
    </Wrapper>
  )
}

export default ScreenNoGeoLocation
