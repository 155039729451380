import React from 'react'
import styled from 'styled-components'
import RowLocation from './components/RowLocation'
import ImageInstaWeather from './components/ImageInstaWeather'
import DataWeather from '../DataWeather'

const StyledImageInstaWeather = styled(ImageInstaWeather)`
  width: ${(props) => props.theme.sizes.col6};
  order: 1;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col6};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col6};
  }
`

const Wrapper = styled.div`
  width: ${(props) => props.theme.sizes.col12};
  display: flex;
  background-color: ${(props) => props.theme.color.background};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-direction: column;
    ${StyledImageInstaWeather} {
      width: 100%;
      height: 400px;
      order: 3;
    }
  }
`


const LiveData = ({ onChange, data, instagram, ...props }) => {
  return (
    <Wrapper {...props}>
      <DataWeather onChange={onChange} data={data} {...props} />
      <StyledImageInstaWeather image={instagram && instagram.images[0]} hashtag={instagram && instagram.tag} />
    </Wrapper>
  )
}

LiveData.propTypes = {
  ...RowLocation.propTypes,
}

export default LiveData

export {
  Wrapper as LiveData0Wrapper,
  StyledImageInstaWeather as LiveDataStyledImageInstaWeather,
}
