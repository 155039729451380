import React from 'react'
import styled from 'styled-components'
import TextSkeleton from '../../../../../../components/Text/views/TextSkeleton'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 15px;
`

const StyledTextSkeleton = styled(TextSkeleton)`
  width: 130px;
`

const StyledTextSkeletonRight = styled(TextSkeleton)`
  margin-left: auto;
  width: 130px;
`

const ItemMonitoringDataSkeleton = styled.div`
  width: 50%;
`

const SkeletonMonitoringData = ({ ...props }) => (
  <Wrapper {...props}>
    <ItemMonitoringDataSkeleton>
      <StyledTextSkeleton lines={6} justify={false} extremity={1.5} fontSize="text" />
    </ItemMonitoringDataSkeleton>
    <ItemMonitoringDataSkeleton>
      <StyledTextSkeletonRight lines={6} justify={false} align="right" extremity={1.5} fontSize="text" />
    </ItemMonitoringDataSkeleton>
  </Wrapper>
)

export default SkeletonMonitoringData
